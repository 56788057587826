import 'regenerator-runtime/runtime';
import axios from 'axios';
import Swiper from 'swiper/swiper-bundle.min.js';
import Masonry from 'masonry-layout';
import stylescss from "bundle-text:./styles.scss";
import MicroModal from 'micromodal';

const BASE_URL = process.env.NODE_ENV === 'development' ? 'https://review-widget-api.dev/api/v1' : 'https://api.reviewwidgetgenerator.com/api/v1';
/*import './preview-scripts';*/
let slider, masonry, floating_rotation_interval = null;
let reviews = null;
const is_local = window.location.origin === 'null';
async function setupPreviewEvents(div){
  div.addEventListener('settings-changed',async (e) => {
    const url = `${BASE_URL}/get-widget/${e.detail.settings.uuid}`;
    await getWidget(url,e.detail.settings).then((resp_settings) => {
      reviews = resp_settings.reviews;
      if(floating_rotation_interval !== null){
        clearInterval(floating_rotation_interval);
      }
      //e.details.layout
      //e.detail.settings.layout_style
      // console.log(e.detail);
      buildWidget(e.target, reviews, e.detail.widget_style, e.detail.settings);
    });
  });
}
function docReady(fn) {
  if (document.readyState === "complete" || document.readyState === "interactive") {
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}
const getReviews = async (unique_id) => {
  try {
    const response = await axios.get(`${BASE_URL}/reviews/${unique_id}`);
    return  response.data;
  } catch (errors) {
    console.error(errors);
  }
};
export const getWidget = async (url,settings = null) => {
  try {
    const setting_response = await axios.post(url,settings);
    return setting_response.data;
  } catch (errors) {
    console.error(errors);
  }
};
const createReviewElement = (item,settings) => {
  let thisElement;
  const reviewElement = document.createElement('div');
  const reviewContainerElement = document.createElement('div');
  let img_element,star_element,date_element,name_element, text_element;
  if(settings.review_style.show_author_picture){
    img_element = document.createElement('img');
    img_element.setAttribute('class','rwg-review-img');
    img_element.src = item.author_image;
  }
  if(settings.review_style.show_author_name){
    name_element = document.createElement('div');
    name_element.setAttribute('class','rwg-review-name');
    name_element.textContent = item.author_title;
  }
  if(settings.review_style.show_rating){
    star_element = document.createElement('div');
    star_element.setAttribute('class','rwg-review-rating');
    const rating_width = (item.review_rating / 5) * 100;
    star_element.innerHTML = `<div class="rating-upper" style="width: ${rating_width}%; color: ${settings.appearance_review.rating_color}"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div> <div class="rating-lower"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>`;
  }
  if(settings.review_style.show_date){
    date_element = document.createElement('div');
    date_element.setAttribute('class','rwg-review-date');
    date_element.textContent = item.days_ago;
  }
  if(item.review_text !== null) {
    text_element = document.createElement('div');
    text_element.setAttribute('class', 'rwg-review-text');
    const random_id = uuidv4();
    if (settings.layout_style !== 'masonry' && (settings.review_style.show_read_more_button === 'true' || (typeof settings.review_style.show_read_more_button == "boolean" && settings.review_style.show_read_more_button))) {
      text_element.innerHTML = `<input type="checkbox" id="rwg-text-${random_id}"><p>${item.review_text}</p> <label class="rwg-text-label" for="rwg-text-${random_id}" role="button" />`;
    } else {
      text_element.innerHTML = `<p>${item.review_text}</p>`;
    }
    setupReadMore(settings,text_element);
  }
  if(settings.layout_style === 'slider' || settings.layout_style === 'masonry'){
    thisElement = reviewContainerElement;
  }else{
    thisElement = reviewElement;
  }
  thisElement.setAttribute('class','rwg-rev-compact');
  thisElement.setAttribute('style',`border-radius:${settings.appearance_review.border_radius}px; background-color:${settings.appearance_review.background_color}; color:${settings.appearance_review.text_color}; font-size:${settings.appearance_review.font_size}px`);
  const reviewCompactTopElement = document.createElement('div');
  reviewCompactTopElement.setAttribute('class','rwg-compact-top');
  const reviewCompactTopLeftElement = document.createElement('div');
  const reviewCompactTopRightElement = document.createElement('div');
  if(settings.review_style.show_author_picture) {
    reviewCompactTopLeftElement.appendChild(img_element);
  }
  if(settings.review_style.show_author_name) {
    reviewCompactTopRightElement.appendChild(name_element);
  }
  reviewCompactTopElement.appendChild(reviewCompactTopLeftElement);
  const reviewCompactTopLowerElement = document.createElement('div');
  reviewCompactTopLowerElement.setAttribute('class','rwg-compact-lower-right');
  if(settings.review_style.show_rating) {
    reviewCompactTopLowerElement.appendChild(star_element)
  }
  if(settings.review_style.show_date) {
    reviewCompactTopLowerElement.appendChild(date_element);
  }
  reviewCompactTopRightElement.appendChild(reviewCompactTopLowerElement);
  reviewCompactTopElement.appendChild(reviewCompactTopRightElement);
  if(settings.settings.enable_external_links){
    const google_user_link = document.createElement('a');
    google_user_link.setAttribute('class','rwg-review-link')
    if(settings.settings.open_links_in_new_window){
      google_user_link.setAttribute('target','_blank');
    }
    google_user_link.setAttribute('href',item.review_link);
    google_user_link.appendChild(reviewCompactTopElement);
    thisElement.appendChild(google_user_link);
  }else {
    thisElement.appendChild(reviewCompactTopElement);
  }
  if(item.review_text !== null) {
    thisElement.appendChild(text_element);
  }
  if(settings.review_style.show_source){
    const posted_on_google_element = document.createElement('div');
    posted_on_google_element.setAttribute('class','rwg-posted-on-source');
    posted_on_google_element.innerHTML = `<div class="rwg-posted-on">Posted on</div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 92" width="64" height="22"><path fill="#EA4335" d="M115.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18C71.25 34.32 81.24 25 93.5 25s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44S80.99 39.2 80.99 47.18c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#FBBC05" d="M163.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#4285F4" d="M209.75 26.34v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"/><path fill="#34A853" d="M225 3v65h-9.5V3h9.5z"/><path fill="#EA4335" d="M262.02 54.48l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"/><path fill="#4285F4" d="M35.29 41.41V32H67c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C16.32 69.35.36 53.89.36 34.91.36 15.93 16.32.47 35.3.47c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65l-22.49.01z"/></svg>`;
    if(settings.settings.enable_external_links){
      const google_link = document.createElement('a');
      google_link.setAttribute('class','rwg-review-link')
      if(settings.settings.open_links_in_new_window){
        google_link.setAttribute('target','_blank');
      }
      google_link.setAttribute('href',item.review_link);
      google_link.appendChild(posted_on_google_element);
      thisElement.appendChild(google_link);
    }else {
      thisElement.appendChild(posted_on_google_element);
    }
  }
  if(thisElement !== reviewElement){
    reviewElement.appendChild(thisElement);
  }
  return reviewElement;
};
const uuidv4 = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
};
const buildWidgetInner = (this_element, reviewItems, type, settings, append = false) => {
  switch(type){
    case 'grid':
      if (Array.isArray(reviewItems) && reviewItems.length > 0) {
        reviewItems.map(reviewItem => {
          this_element.appendChild(createReviewElement(reviewItem, settings));
        });
      }
      setupReadMore(settings,this_element);
      break;
    case 'slider':
      if (Array.isArray(reviewItems) && reviewItems.length > 0) {
        reviewItems.map(reviewItem => {
          this_element.appendChild(createReviewElement(reviewItem, settings)).setAttribute('class','swiper-slide');
        });
      }
      break;
    case 'masonry':
      if (Array.isArray(reviewItems) && reviewItems.length > 0) {
        reviewItems.map(reviewItem => {
          const grid_element = createReviewElement(reviewItem, settings);
          grid_element.setAttribute('class','grid-item');
          //settings.layout_masonry.columns
          grid_element.setAttribute('style',`width:${100/3}%; padding:${settings.appearance_review.gap / 2}px;`);
          this_element.appendChild(grid_element);
          if(append){
           masonry.appended(grid_element);
          }
        });
      }
      break;
    case 'list':
    case 'floating':
    case 'floating_rotator':
      if (Array.isArray(reviewItems) && reviewItems.length > 0) {
        reviewItems.map(reviewItem => {
          this_element.appendChild(createReviewElement(reviewItem, settings));
        });
      }
      break;
  }
  return this_element;
};
export const buildWidget = (div, paginatedReviewItems, layout_type, settings, is_shadow = false) => {
  const reviewItems = paginatedReviewItems.data;
  if(slider !== null && typeof slider === 'object' && layout_type !== 'floating_rotator'){
    slider.destroy();
  }
  if(masonry !== null && typeof masonry === 'object' && layout_type !== 'floating_rotator'){
    masonry.destroy();
    masonry = null;
  }
  if(settings.settings.font.font_family && settings.settings.font.font_family !== 'system'){
    div.setAttribute('style',`font-family:${settings.settings.font.font_family}; padding:30px`);
  }else{
    div.setAttribute('style','font-family:revert; padding:30px');
  }

  div.innerHTML = '';
  if(layout_type === 'floating_rotator'){
    div.classList.add('floating');
  }else{
    div.classList.remove('floating');
  }
  if(!settings.full_width && settings.defined_width && layout_type !== 'floating'){
    div.setAttribute('style',`width:100%; max-width:${settings.defined_width}px`)
  }
  const title_element = document.createElement('div');
  if(settings.include_title){
    title_element.setAttribute('class','rwg-title');
    title_element.setAttribute('style',`color:${settings.appearance_review.text_color};`)
    title_element.innerHTML = settings.title;
    if(!layout_type.includes('floating')) {
      div.appendChild(title_element);
    }
  }
  const caption_element = document.createElement('div');
  if(settings.caption && settings.caption !== ""){
    caption_element.setAttribute('class','rwg-caption');
    caption_element.setAttribute('style',`color:${settings.appearance_review.text_color}; font-size:${settings.appearance_review.font_size}px;`)
    caption_element.innerHTML = settings.caption;
    if(!layout_type.includes('floating')) {
      div.appendChild(caption_element);
    }
  }
  if(settings.show_review_header && layout_type !== 'floating_rotator'){
    const review_header = document.createElement('div');
    review_header.setAttribute('class','rwg-review-header');
    review_header.setAttribute('id','rwg-review-header');
    review_header.setAttribute('style',`background-color: ${settings.appearance_review.background_color}; color:${settings.appearance_review.text_color}; font-size:${settings.appearance_review.font_size}px; border-radius:${settings.appearance_review.border_radius}px;`);
    if(settings.show_review_header && layout_type === 'slider'){
      if(is_shadow){
        review_header.classList.add('rwg-header-slider');
      }else{
        review_header.classList.add('rwg-header-slider');
      }
    }
    let average;
    if(session_data) {
      average = session_data.rating;
    }else{
      average = paginatedReviewItems.review_average;
    }
    let review_total;
    if(session_data) {
      review_total = session_data.total_review;
    }else{
      review_total = paginatedReviewItems.total_reviews;
    }
    const review_header_left = document.createElement('div');
    review_header_left.setAttribute('class','rwg-review-header-left');
    const business_name = document.createElement('div');
    business_name.setAttribute('class','rwg-header-business');
    let businessname;
    if(session_data) {
      businessname = session_data.business_name;
    }else{
      businessname = settings.business.business_name
    }
    business_name.innerHTML = businessname;
    review_header_left.appendChild(business_name);
    if(settings.review_style.show_source){
      const posted_on_google_header_element = document.createElement('div');
      posted_on_google_header_element.setAttribute('class','rwg-posted-on-source');
      posted_on_google_header_element.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 92" width="87" height="30"><path fill="#EA4335" d="M115.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18C71.25 34.32 81.24 25 93.5 25s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44S80.99 39.2 80.99 47.18c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#FBBC05" d="M163.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#4285F4" d="M209.75 26.34v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"/><path fill="#34A853" d="M225 3v65h-9.5V3h9.5z"/><path fill="#EA4335" d="M262.02 54.48l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"/><path fill="#4285F4" d="M35.29 41.41V32H67c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C16.32 69.35.36 53.89.36 34.91.36 15.93 16.32.47 35.3.47c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65l-22.49.01z"/></svg> <span class="source-rating-text">Rating</span>`;
      review_header.appendChild(posted_on_google_header_element);
      review_header_left.appendChild(posted_on_google_header_element);
    }
    const review_header_left_bottom_row = document.createElement('div');
    review_header_left_bottom_row.setAttribute('class','rwg-review-header-bottom');
    if(settings.review_style.show_rating) {
      const count_element = document.createElement('div');
      count_element.setAttribute('class','rwg-review-avg-header');
      count_element.innerHTML = average.toFixed(1);
      review_header_left_bottom_row.appendChild(count_element);

      const star_element_upper = document.createElement('div');
      star_element_upper.setAttribute('class','rwg-review-rating');
      const rating_width_upper = (average / 5) * 100;
      star_element_upper.innerHTML = `<div class="rating-upper" style="width: ${rating_width_upper}%; color: ${settings.appearance_review.rating_color}"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div> <div class="rating-lower"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>`;
      review_header_left_bottom_row.appendChild(star_element_upper);

      const total_element = document.createElement('div');
      total_element.setAttribute('class','rwg-review-total');
      total_element.setAttribute('style',`color:${settings.appearance_review.text_color};`);
      total_element.innerHTML = `${review_total} reviews`;
      review_header_left_bottom_row.appendChild(total_element);
      review_header_left.appendChild(review_header_left_bottom_row);
    }
    review_header.appendChild(review_header_left);
    if(settings.show_write_review){
      const write_review = document.createElement('div');
      write_review.setAttribute('class','rwg-write-review');
      if(!place_id){
        place_id = settings.business.aux_place_id ?? settings.business.place_id;
      }
      let review_button_html = '';
      if(settings.settings.open_links_in_new_window){
        review_button_html = `<a target="_blank" href="https://search.google.com/local/writereview?placeid=${place_id}" style="background-color:${settings.appearance_review.write_review_background_color}; color:${settings.appearance_review.write_review_text_color};">Write A Review</a>`;
      }else{
        review_button_html =  `<a href="https://search.google.com/local/writereview?placeid=${place_id}" style="background-color:${settings.appearance_review.write_review_background_color}; color:${settings.appearance_review.write_review_text_color};">Write A Review</a>`;
      }
      if(settings.settings.gate_review && settings.business){
        const modal_id = uuidv4();
        const review_link = `https://search.google.com/local/writereview?placeid=${place_id}`;
        review_button_html = `<button data-micromodal-trigger="${modal_id}" style="background-color:${settings.appearance_review.write_review_background_color}; color:${settings.appearance_review.write_review_text_color};">Write A Review</button>`;
        write_review.innerHTML = review_button_html;
        review_header.appendChild(write_review);
        writeModal(div,modal_id,review_link,settings)
      }else {
        write_review.innerHTML = review_button_html;
        review_header.appendChild(write_review);
      }

    }
    div.appendChild(review_header);

  }
  let inner_widget;
  switch(layout_type){
    case 'grid':
      const grid_element = document.createElement('div');
      grid_element.setAttribute('class','rwg-grid');
      //${settings.layout_grid.columns}
      grid_element.setAttribute('style',`grid-template-columns: repeat(3, minmax(0px, 1fr)); gap: ${settings.appearance_review.gap}px;  margin-top:${settings.appearance_review.gap}px;`)
      inner_widget = buildWidgetInner(grid_element, reviewItems, layout_type, settings);
      div.appendChild(inner_widget);
      break;
    case 'slider':
      const swiper_outside_element = document.createElement('div');
      swiper_outside_element.setAttribute('class','rwg-swiper-outer');
      swiper_outside_element.setAttribute('style',`margin-top:${settings.appearance_review.gap}px;`);
      const swiper_element = document.createElement('div');
      swiper_element.setAttribute('class','swiper rwg-contain-'+layout_type);
      const swiper_wrapper_element = document.createElement('div');
      swiper_wrapper_element.setAttribute('class','swiper-wrapper');
      inner_widget = buildWidgetInner(swiper_wrapper_element, reviewItems, layout_type, settings);
      swiper_element.appendChild(inner_widget);
      if(settings.layout_slider.enable_arrows) {
        const left_button = document.createElement('div');
        left_button.setAttribute('class', 'swiper-button-prev');
        /*left_button.setAttribute('style',`color:${settings.appearance_navigation.arrow_color}`)*/
        swiper_outside_element.appendChild(left_button);

        const right_button = document.createElement('div');
        right_button.setAttribute('class', 'swiper-button-next');
        /*right_button.setAttribute('style',`color:${settings.appearance_navigation.arrow_color}`)*/
        swiper_outside_element.appendChild(right_button);
      }
      swiper_outside_element.appendChild(swiper_element);
      div.appendChild(swiper_outside_element);
      let next_element = '.swiper-button-next';
      if(is_shadow){
        next_element = document.querySelector('review-widget-generator').shadowRoot.querySelector('.swiper-button-next');
      }
      let prev_element = '.swiper-button-prev';
      if(is_shadow){
        prev_element = document.querySelector('review-widget-generator').shadowRoot.querySelector('.swiper-button-prev');
      }
      let slide_element = '.swiper';
      if(is_shadow){
        slide_element = document.querySelector('review-widget-generator').shadowRoot.querySelector('.swiper');
      }
      slider = new Swiper(slide_element, {
        autoplay: !settings.layout_slider.autorotation ? false : {
          delay: settings.layout_slider.autorotate_delay * 1000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable:true,
        },
        navigation: {
          nextEl: next_element,
          prevEl: prev_element,
        },
        slideNextClass:'.rwg-slider-button-next',
        slidesPerView: 1,
        spaceBetween: settings.appearance_review.gap,
        breakpoints:{
          640:{
            slidesPerView:2
          },
          860:{
            slidesPerView: settings.layout_slider?.columns,
          }
        }
      });
      break;
    case 'masonry':
      const masonry_element = document.createElement('div');
      masonry_element.setAttribute('class','rwg-masonry-grid');
      masonry_element.setAttribute('style',`margin-left:-${settings.appearance_review.gap / 2}px; margin-right:-${settings.appearance_review.gap /2}px; margin-top:${settings.appearance_review.gap / 2}px;`)
      inner_widget = buildWidgetInner(masonry_element, reviewItems, layout_type, settings);
      div.appendChild(inner_widget);
      masonry = new Masonry(masonry_element, {
        // options
        itemSelector: '.grid-item',
        horizontalOrder: false,
        percentPosition: true,
      });
      break;
    case 'list':
      const list_element = document.createElement('div');
      list_element.setAttribute('class','rwg-list')
      list_element.setAttribute('style',`grid-template-columns: 1fr; gap: ${settings.appearance_review.gap}px; margin-top:${settings.appearance_review.gap}px;`)
      inner_widget = buildWidgetInner(list_element, reviewItems, layout_type, settings);
      div.appendChild(inner_widget);
      break;
    case 'floating_rotator':
      if(floating_rotation_interval != null){
        clearInterval(floating_rotation_interval);
      }
      if(is_local){
        const preview_button = document.createElement('button');
        preview_button.classList.add('floating-preview');
        preview_button.setAttribute('type','button');
        preview_button.innerText = "Preview";
        div.appendChild(preview_button);
        preview_button.addEventListener('click', function (event) {
          if(event.target.innerText === 'Preview'){
            event.target.innerText = 'Stop Preview';
            document.getElementById('rwg-floating-rotator').classList.remove('is-local');
            document.getElementById('rwg-floating-rotator').classList.remove('visible');
            floatingCounter = 0;
            floating_rotation_interval = setInterval(function () {
              if (floatingCounter >= paginatedReviewItems.data.length) {
                floatingCounter = 0;
              }
              setupFloatingData(paginatedReviewItems.data[floatingCounter], settings, is_shadow);
            }, (settings.layout_floating_rotator.delay * 1000) + 3000);
          }else{
            if(floating_rotation_interval !== null){
              clearInterval(floating_rotation_interval);
            }
            event.target.innerText = 'Preview';
            document.getElementById('rwg-floating-rotator').classList.add('is-local');
            document.getElementById('rwg-floating-rotator').classList.add('visible');
          }
        });
      }
      /*div.setAttribute('style','position:fixed; bottom:10px;');*/
      const floating_rotator = document.createElement('div');
      floating_rotator.setAttribute('id','rwg-floating-rotator');
      floating_rotator.setAttribute('style',`border-radius:${settings.appearance_review.border_radius}px; background-color:${settings.appearance_review.background_color}; font-size:${settings.appearance_review.font_size}px; ${settings.layout_floating_rotator.position}:10px`)
      if(is_local){
        floating_rotator.classList.add('is-local');
      }
      if(settings.review_style.show_author_picture){
        const img_element = document.createElement('img');
        img_element.setAttribute('class','rwg-review-img');
        img_element.setAttribute('id','rwg-floating-image');
        floating_rotator.appendChild(img_element);
      }
      const floating_main = document.createElement('div');
      floating_main.setAttribute('class','rwg-floating-main');
      if(settings.review_style.show_author_name) {
        const name_element = document.createElement('div');
        name_element.setAttribute('class','rwg-review-name');
        name_element.setAttribute('id','rwg-floating-name');
        name_element.setAttribute('style',`color:${settings.appearance_review.text_color};`);
        floating_main.appendChild(name_element);
      }
      const text_element = document.createElement('div');
      text_element.setAttribute('class', 'rwg-review-text');
      text_element.setAttribute('id', 'rwg-floating-text');
      text_element.setAttribute('style',`color:${settings.appearance_review.text_color};`);
      floating_main.appendChild(text_element);
      const floating_main_footer = document.createElement('div');
      floating_main_footer.setAttribute('class','rwg-floating-main-footer');
      if(settings.review_style.show_source){
        const posted_on_google_element = document.createElement('div');
        posted_on_google_element.setAttribute('class','rwg-posted-on-source');
        posted_on_google_element.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 92" width="64" height="22"><path fill="#EA4335" d="M115.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18C71.25 34.32 81.24 25 93.5 25s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44S80.99 39.2 80.99 47.18c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#FBBC05" d="M163.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"/><path fill="#4285F4" d="M209.75 26.34v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"/><path fill="#34A853" d="M225 3v65h-9.5V3h9.5z"/><path fill="#EA4335" d="M262.02 54.48l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"/><path fill="#4285F4" d="M35.29 41.41V32H67c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C16.32 69.35.36 53.89.36 34.91.36 15.93 16.32.47 35.3.47c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65l-22.49.01z"/></svg>`
        floating_main_footer.appendChild(posted_on_google_element);
      }
      if(settings.review_style.show_rating) {
        const star_element = document.createElement('div');
        star_element.setAttribute('class','rwg-review-rating');
        star_element.innerHTML = `<div class="rating-upper" id="rwg-floating-rating" style="color: ${settings.appearance_review.rating_color}"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div> <div class="rating-lower"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>`;
        floating_main_footer.appendChild(star_element);
      }
      if(settings.review_style.show_date) {
        const date_element = document.createElement('div');
        date_element.setAttribute('class','rwg-review-date');
        date_element.setAttribute('id','rwg-floating-date');
        date_element.setAttribute('style',`color:${settings.appearance_review.text_color};`);
        floating_main_footer.appendChild(date_element);
      }
      floating_main.appendChild(floating_main_footer);
      const floating_close_button = document.createElement('button');
      floating_close_button.setAttribute('class','rwg-floating-close rwg-sess-close');
      floating_close_button.setAttribute('type','button');
      floating_close_button.innerHTML = `<svg style="width:15px;height:15px; color:${settings.appearance_review.text_color}" viewBox="0 0 24 24" class="rwg-sess-close"><path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" class="rwg-sess-close" /></svg>`;
      if(settings.settings.enable_external_links){
        const link = document.createElement('a');
        link.setAttribute('id','rwg-floating-link')
        if(settings.settings.open_links_in_new_window){
          link.setAttribute('target','_blank');
        }
        link.appendChild(floating_main);
        floating_rotator.appendChild(link);
      }else{
        floating_rotator.appendChild(floating_main);
      }
      floating_rotator.appendChild(floating_close_button);
      if(!sessionStorage.getItem('rwg-floating-rotator-disable-'+place_id)) {
        div.appendChild(floating_rotator);
        if(floating_rotator.classList.contains('is-local')){
          if(floating_rotation_interval != null){
            clearInterval(floating_rotation_interval);
          }
          setupFloatingData(paginatedReviewItems.data[0], settings);
        }else {
          floating_rotation_interval = setInterval(function () {
            if (floatingCounter >= paginatedReviewItems.data.length) {
              floatingCounter = 0;
            }
            setupFloatingData(paginatedReviewItems.data[floatingCounter], settings);
          }, (settings.layout_floating_rotator.delay * 1000) + 3000);
        }
        floating_rotator.addEventListener('click', function (event) {
          if (event.target.classList.contains('rwg-sess-close')) {
            if(!is_local) {
              sessionStorage.setItem('rwg-floating-rotator-disable-'+place_id, true);
              clearInterval(floating_rotation_interval);
            }
            document.getElementById('rwg-floating-rotator').classList.remove('visible');
            if(floating_rotator.classList.contains('is-local')) {
              setTimeout(()=>{
                document.getElementById('rwg-floating-rotator').classList.add('visible');
              },1000)
            }
          }
        });
      }
      break;
  }
  if(paginatedReviewItems.next_page_url && layout_type !== 'slider' && layout_type !== 'floating_rotator'){
    const load_more_container = document.createElement('div');
    load_more_container.setAttribute('class','rwg-load-more-contain');
    const load_more_link = document.createElement('a');
    load_more_link.setAttribute('id','rwg-load-more');
    load_more_link.setAttribute('href',paginatedReviewItems.next_page_url);
    load_more_link.setAttribute('style',`background:${settings.appearance_review.background_color}; color:${settings.appearance_review.text_color}; font-size:${settings.appearance_review.font_size}px; border-radius:${settings.appearance_review.border_radius}px`);
    load_more_link.innerText = "Load More";
    load_more_link.addEventListener("click", function(event){
      event.preventDefault();
      getWidget(event.target.href,settings).then((resp_settings) => {
        let current_div;
        reviews = resp_settings.reviews;
        switch(layout_type) {
          case 'grid':
            current_div = document.querySelector('.rwg-grid');
            break;
          case 'slider':
            break;
          case 'masonry':
            current_div = document.querySelector('.rwg-masonry-grid');
            break;
          case 'list':
            current_div = document.querySelector('.rwg-list');
            break;
          case 'floating':
            current_div = document.querySelector('.rwg-floating-panel-reviews');
            break
        }
        buildWidgetInner(current_div, resp_settings.reviews.data, layout_type, settings, true);
        load_more_link.setAttribute('href',resp_settings.reviews.next_page_url);
        if(!reviews.next_page_url){
          event.target.remove();
        }
      });
    });
    load_more_container.appendChild(load_more_link);
    if(layout_type === 'floating'){
      document.querySelector('.rwg-floating-panel__content').appendChild(load_more_container);
    }else {
      div.appendChild(load_more_container);
    }
  }
  if(settings.settings.gate_review){
    MicroModal.init({
      onClose: modal => {
        document.getElementById(modal.id).querySelector('.title').innerHTML = 'How would you rate your experience?';
        document.getElementById(modal.id).querySelector('.gate-buttons').setAttribute('style','display:flex');
        document.getElementById(modal.id).querySelector('.negative-form').setAttribute('style','display:none');
        if(document.getElementById(modal.id).querySelector('.success-message')){
          document.getElementById(modal.id).querySelector('.success-message').remove();
        }
      }
    });
  }
};
const writeModal = (div,modal_id,review_link,settings) => {
  let review_button = `<a class="positive" href="${review_link}">
    <svg style="width:75px;height:75px" viewBox="0 0 24 24">
      <path fill="currentColor"
            d="M23,10C23,8.89 22.1,8 21,8H14.68L15.64,3.43C15.66,3.33 15.67,3.22 15.67,3.11C15.67,2.7 15.5,2.32 15.23,2.05L14.17,1L7.59,7.58C7.22,7.95 7,8.45 7,9V19A2,2 0 0,0 9,21H18C18.83,21 19.54,20.5 19.84,19.78L22.86,12.73C22.95,12.5 23,12.26 23,12V10M1,21H5V9H1V21Z"/>
    </svg>
  </a>`;
  if(settings.settings.open_links_in_new_window){
    //href="${review_link}"
    review_button = `<a target="_blank" href="${review_link}" class="positive" onclick="MicroModal.close('${modal_id}');">
      <svg style="width:75px;height:75px" viewBox="0 0 24 24">
        <path fill="currentColor"
              d="M23,10C23,8.89 22.1,8 21,8H14.68L15.64,3.43C15.66,3.33 15.67,3.22 15.67,3.11C15.67,2.7 15.5,2.32 15.23,2.05L14.17,1L7.59,7.58C7.22,7.95 7,8.45 7,9V19A2,2 0 0,0 9,21H18C18.83,21 19.54,20.5 19.84,19.78L22.86,12.73C22.95,12.5 23,12.26 23,12V10M1,21H5V9H1V21Z"/>
      </svg>
    </a>`;
  }
  const modal_html = '' +
      `  <div class="modal micromodal-slide" id="${modal_id}" aria-hidden="true">` +
      '    <div class="modal__overlay" tabindex="-1" data-micromodal-close>' +
      '      <div class="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">' +
      '        <main class="modal__content" id="modal-1-content">' +
      `          <p class="gate-header title">How would you rate your experience?</p>` +
      '          <p class="gate-buttons">' +
                    review_button +
      `             <button id="negativebutton-${modal_id}" class="negativebutton-${modal_id} negative" type="button"><svg style="width:75px;height:75px" viewBox="0 0 24 24"><path fill="currentColor" d="M19,15H23V3H19M15,3H6C5.17,3 4.46,3.5 4.16,4.22L1.14,11.27C1.05,11.5 1,11.74 1,12V14A2,2 0 0,0 3,16H9.31L8.36,20.57C8.34,20.67 8.33,20.77 8.33,20.88C8.33,21.3 8.5,21.67 8.77,21.94L9.83,23L16.41,16.41C16.78,16.05 17,15.55 17,15V5C17,3.89 16.1,3 15,3Z" /></svg></button>` +
      '          </p>' +
      '           <div style="display:none;" class="negative-form">' +
      `              <form action="">` +
      '                 <label for="name">Name</label>' +
      '                 <input type="text" name="name" class="gate-name" />' +
      '                 <label for="email">Email</label>' +
      '                 <input type="text" name="email" class="gate-email" />' +
      '                 <label for="feedback">Feedback</label>' +
      '                 <textarea rows="10" name="feedback" class="gate-feedback"></textarea>' +
      `                 <button data-business-id="${settings.business.id}" class="gate-submit" type="button" style="background-color:${settings.appearance_review.write_review_background_color}; color:${settings.appearance_review.write_review_text_color};"><span>Submit</span></button>` +
      '              </form>' +
      `              <div class="skip-gate">If you prefer to just leave a review, <a href="${review_link}">click here</a></div>` +
      '           </div>' +
      '        </main>' +
      /*        '        <footer class="modal__footer">' +
              '          <button class="modal__btn modal__btn-primary">Continue</button>' +
              '          <button class="modal__btn" data-micromodal-close aria-label="Close this dialog window">Close</button>' +
              '        </footer>' +*/
      '      </div>' +
      '    </div>' +
      '  </div>' +
      '';
  const modal_div = document.createElement('div');
  modal_div.innerHTML = modal_html;
  div.append(modal_div);
};
let floatingCounter = 0;
const setupFloatingData = (review, settings, is_shadow = false) => {
  let this_doc = document;
  if(is_shadow){
    this_doc = document.querySelector('review-widget-generator').shadowRoot;
  }
  this_doc.getElementById('rwg-floating-rotator').classList.add('visible');
  if(settings.review_style.show_author_picture){
    this_doc.getElementById('rwg-floating-image').src = review.author_image;
  }
  if(settings.review_style.show_author_name) {
    this_doc.getElementById('rwg-floating-name').innerHTML = review.author_title;
  }
  if(settings.review_style.show_rating) {
    const rating_width = (review.review_rating / 5) * 100;
    this_doc.getElementById('rwg-floating-rating').setAttribute('style',`width: ${rating_width}%; color: ${settings.appearance_review.color}`);
  }
  if(settings.review_style.show_date) {
    this_doc.getElementById('rwg-floating-date').innerHTML = review.days_ago;
  }
  if(settings.settings.enable_external_links) {
    this_doc.getElementById('rwg-floating-link').setAttribute('href', review.review_link);
  }
  const floating_text = this_doc.getElementById('rwg-floating-text');
  if(review.review_text){
    floating_text.innerHTML = `<p>${review.review_text}</p>`;
  }else{
    floating_text.innerHTML = `<p></p>`;
  }
  floatingCounter++;
  if(!this_doc.getElementById('rwg-floating-rotator').classList.contains('is-local')) {
    setTimeout(() => {
      this_doc.getElementById('rwg-floating-rotator').classList.remove('visible');
    }, (settings.layout_floating_rotator.delay * 1000));
  }
};
const setupReadMore = (settings,elem) => {
  if((settings.review_style.show_read_more_button === 'true' || (typeof settings.review_style.show_read_more_button == "boolean" && settings.review_style.show_read_more_button)) && settings.layout_style !== 'masonry') {
    const ps = elem.querySelector('p');
    ps.classList.add('trunc-enabled');
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        entry.target.classList[entry.target.scrollHeight > entry.contentRect.height ? 'add' : 'remove']('truncated');
      }
    });
    observer.observe(ps);
  }else{
    const ps = elem.querySelector('p');
    ps.classList.remove('trunc-enabled');
  }
}
let session_data, place_id = null;
const setupScripts = (doc,is_shadow = false) => {
  if(!doc.querySelector('#g-preconnect1')){
    const preconnect1 = document.createElement('link');
    preconnect1.setAttribute('rel','reconnect');
    preconnect1.setAttribute('href','https://fonts.googleapis.com');
    preconnect1.setAttribute('id','g-preconnect1');
    /*if(doc.head !== 'undefined') {
      doc.head.appendChild(preconnect1);
    }else{
      doc.appendChild(preconnect1);
    }*/
    doc.parentNode.appendChild(preconnect1);
  }
  if(!doc.querySelector('#g-preconnect2')){
    const preconnect2 = document.createElement('link');
    preconnect2.setAttribute('rel','reconnect');
    preconnect2.setAttribute('href','https://fonts.gstatic.com');
    preconnect2.setAttribute('crossorigin','');
    preconnect2.setAttribute('id','g-preconnect2');
    /*if(is_shadow) {
      doc.appendChild(preconnect2);
    }else{
      doc.head.appendChild(preconnect2);
    }*/
    doc.parentNode.appendChild(preconnect2);
  }
  if(!doc.querySelector('#rwg-styles')){
    const stylesheet = document.createElement('style');
    stylesheet.textContent = stylescss;
    stylesheet.setAttribute('id','rwg-styles');
    /*if(is_shadow) {
      doc.appendChild(stylesheet);
    }else{
      doc.head.appendChild(stylesheet);
    }*/
    doc.parentNode.appendChild(stylesheet);
  }
};
const setupRWG = async () => {
  let all_widgets = Array.prototype.slice.call(document.querySelectorAll("[class^=rwg-app]"));
  let example_widgets = [];
  if(document.querySelector('review-widget-generator') != null && document.querySelector('review-widget-generator').shadowRoot != null){
    example_widgets = Array.prototype.slice.call(document.querySelector('review-widget-generator').shadowRoot.querySelectorAll("[class^=rwg-app]"));
    all_widgets.push.apply(all_widgets,example_widgets);
  }
  let widget_id = null;
  let has_loaded = false;
  for(const embed of all_widgets){
    let is_shadow = example_widgets.includes(embed)
    /*for(const doc of document_parents){
      if(typeof doc.host !== 'undefined'){
        is_shadow = true;
      }
    }
    console.log({
      all_length:all_widgets.length,
      example_length:example_widgets.length,
      is_shadow:is_shadow,
      has_loaded:has_loaded
    })
    */
    if(has_loaded){
      continue;
    }
    setupScripts(embed,is_shadow);
    const css_class = embed.getAttribute('class');
    const correct_class = css_class.split(' ');
    if(!correct_class.length || !correct_class[0].startsWith('rwg-app-')){
      console.log("proper rwg class is missing");
    }else{
      widget_id = correct_class[0].replace('rwg-app-','');
      if(widget_id != null && widget_id !== 'undefined'){
        let url = `${BASE_URL}/get-widget/${widget_id}`;
        switch(widget_id){
          case "example-slider":
            url =  `${BASE_URL}/example-widget/slider`;
            break;
          case "example-floating":
            url = `${BASE_URL}/example-widget/floating`;
            break;
        }
        let settings = null;
        if(embed.classList.contains('floating')){
          settings = {is_rotator:true};
        }
        await getWidget(url,settings).then((resp_settings) => {
          if(resp_settings.settings.font.backend_name !== 'system' && !document.getElementById(resp_settings.settings.font.frontend_label)){
            const chosen_font = document.createElement('link');
            chosen_font.setAttribute('rel','stylesheet');
            chosen_font.setAttribute('href',resp_settings.settings.font.href);
            chosen_font.setAttribute('id',resp_settings.settings.font.frontend_label);
            document.head.appendChild(chosen_font);
          }
          if(widget_id.includes("example-")){
            //setup Event for Example event
            place_id = embed.dataset.placeId;
            session_data = JSON.parse(sessionStorage.getItem('rwg_'+place_id));
            reviews = session_data && session_data.reviews;
          }else {
            reviews = resp_settings.reviews;
          }
          if(window.origin.includes('localhost') || window.location.origin.includes('https://app.reviewwidgetgenerator.com') || window.location.origin === 'null'){
            setupPreviewEvents(embed);
          }
          let style = resp_settings.layout_style;
          if(settings !== null){
            style = 'floating_rotator';
          }
          if(reviews) {
            buildWidget(embed, reviews, style, resp_settings, is_shadow);
          }
          const schema_script = document.getElementById('rwg-schema');
          if(resp_settings.settings.enable_schema){
            if(schema_script === null) {
              const schema_script = document.createElement('script');
              schema_script.setAttribute('type', 'application/ld+json');
              schema_script.setAttribute('id', 'rwg-schema');
              schema_script.innerHTML = JSON.stringify(resp_settings.schema);
              document.body.appendChild(schema_script);
            }
          }else if(schema_script !== null){
            schema_script.remove();
          }
        });
      }
    }
  }
  if(!has_loaded){
    //setup negative button events
    const negativeClick = (event) => {
      const button_class = event.currentTarget.getAttribute('class');
      const first_class = button_class.split(' ');
      const div_id = first_class[0].replace('negativebutton-','');
      const div = document.getElementById(div_id);
      div.querySelector('.title').innerHTML = 'Please leave your feedback below';
      div.querySelector('.gate-buttons').setAttribute('style','display:none');
      div.querySelector('.negative-form').setAttribute('style','display:block');
    };
    const negative_buttons = document.querySelectorAll("[class^=negativebutton]");
    for(const button of negative_buttons){
      button.addEventListener('click',negativeClick)
    }
    document.addEventListener("click", async function(e){
      const target = e.target.closest(".gate-submit"); // Or any other selector.

      if(target){
        window.rwg_feedback_form_errors = [];
        const form = e.target.closest('form');
        const errors = form.querySelector('.gate-errors');
        if(errors) {
          errors.remove();
        }
        const success_elem = form.querySelector('.success-message');
        if(success_elem){
          success_elem.remove();
        }
        const name = form.querySelector('.gate-name');
        if(name && name.value === ''){
          window.rwg_feedback_form_errors.push("Name cannot be empty");
        }
        const email = form.querySelector('.gate-email');
        if(email){
          if(email.value === '') {
            window.rwg_feedback_form_errors.push("Email cannot be empty");
          }
          if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value) && email.value !== ''){
            window.rwg_feedback_form_errors.push("Email must be a valid email address");
          }
        }
        const feedback = form.querySelector('.gate-feedback');
        if(feedback && feedback.value === ''){
          window.rwg_feedback_form_errors.push("Feedback cannot be empty");
        }
        if(window.rwg_feedback_form_errors.length){
          const ul_element = document.createElement('ul');
          ul_element.setAttribute('class','gate-errors');
          window.rwg_feedback_form_errors.forEach(element => {
            const li_element = document.createElement('li');
            li_element.innerHTML = element.toString();
            ul_element.appendChild(li_element);
          });
          form.prepend(ul_element);
        }else{
          //Submit the form
          target.classList.add('is-working');
          const business_id = target.getAttribute('data-business-id');
          const submission_url = `${BASE_URL}/add-feedback/${business_id}`;
          await axios.post(submission_url, {
            name:name.value,
            email:email.value,
            feedback:feedback.value,
            id:target.getAttribute('data-business-id'),
            request_key:'rwg_pass'
          }).catch(error => {
            console.log(error.response.data.errors)
            const ul_element_e = document.createElement('ul');
            ul_element_e.setAttribute('class','gate-errors');
            Object.values(error.response.data.errors).forEach(element => {
              const li_element = document.createElement('li');
              li_element.innerHTML = element[0].toString();
              ul_element_e.appendChild(li_element);
            });
            form.prepend(ul_element_e);
          }).then(response => {
            if(response.data.success){
              const success_element = document.createElement('div');
              success_element.setAttribute('class','success-message');
              success_element.innerText = response.data.message;
              form.appendChild(success_element);
              form.reset();
            }
          }).finally(() => {
            target.classList.remove('is-working');
          });
        }
      }
    });
  }
  has_loaded = true;
};
window.rwg_feedback_form_errors = [];
window.rwg_setup = setupRWG;
docReady(function() {
  setupRWG();
  setTimeout(function(){
    document.documentElement.addEventListener('setupRWG',async (e)=>{
      await setupRWG();
    });
  },100);
});
